import db, { firebaseAuth } from "./";

// When the auth state changes
export function authChangeListener() {
  firebaseAuth().onAuthStateChanged(user => {
    this.setState({ isConnecting: true });

    if (user) {
      console.log("User logged in: " + user.uid);
      window.localStorage.setItem("isAuthenticated", "true");

      this.setState({
        isConnecting: false,
        user: {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user.displayName,
          lastSignInTime: user.metadata.lastSignInTime
        },
        isAuthenticated: true
      });
    } else {
      console.log("No user logged in");
      window.localStorage.removeItem("isAuthenticated");

      this.setState({
        isAuthenticated: false,
        isConnecting: false
      });
    }
  });
}

// Sign in
export function signIn(email, password, persistence) {
  console.log("Attempting sign on");

  return new Promise((resolve, reject) => {
    firebaseAuth()
      .setPersistence(firebaseAuth.Auth.Persistence[persistence])
      .then(() => {
        firebaseAuth()
          .signInWithEmailAndPassword(email, password)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            reject({ message: "Username or password incorrect" });
          });
      });
  });
}

// Sign out
export function signOut() {
  return new Promise((resolve, reject) => {
    firebaseAuth()
      .signOut()
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        reject({ message: "Unable to sign out at this time" });
      });
  });
}

// Send email verification email
export function verifyEmail() {
  return new Promise((resolve, reject) => {
    firebaseAuth()
      .currentUser.sendEmailVerification()
      .then(res => {
        resolve({ message: "Verification email sent" });
      })
      .catch(res => {
        reject({ message: "Error sending verification email" });
      });
  });
}

// Get user preferences
export function getPreferences() {
  return new Promise((resolve, reject) => {
    let uid = firebaseAuth().currentUser.uid;
    let docRef = db.collection("users").doc(uid);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log("User preferences: ", doc.data());
          resolve(doc.data());
        } else {
          console.log("No user preferences set yet");

          // Default preferences
          resolve({
            displayName: "",
            language: 0
          });
        }
      })
      .catch(err => {
        console.log("Error fetching document: ", err);
        reject(err);
      });
  });
}

// Update user preferences
export function updatePreferences(preferences) {
  return new Promise((resolve, reject) => {
    let uid = firebaseAuth().currentUser.uid;
    let docRef = db.collection("users").doc(uid);

    docRef
      .set({ ...preferences }, { merge: true })
      .then(() => {
        resolve({ message: "Preferences updated" });
      })
      .catch(() => {
        reject({ message: "Unable to update preferences at this time" });
      });
  });
}
