const firebaseConfig = {
  apiKey: "AIzaSyDNlaxOacvMQKsmJ5PLW8FrDSAUcUnaRDc",
  authDomain: "artemus-dbb9a.firebaseapp.com",
  databaseURL: "https://artemus-dbb9a.firebaseio.com",
  projectId: "artemus-dbb9a",
  storageBucket: "artemus-dbb9a.appspot.com",
  messagingSenderId: "1034565470213",
  appId: "1:1034565470213:web:f46c3e88152626c46a346c",
  measurementId: "G-1QT4GJLJSK"
}

export default firebaseConfig;