import React from "react";
import styled from "styled-components";

const StyledHeaderSearchBar = styled.div`
  display: flex;
  flex: 1 1;
  box-sizing: border-box;
  justify-content: flext-start;
  text-align: center;
  box-sizing: border-box;
  line-height: 35px;
  border: solid 1px #ddd;
  border-radius: 4px;
  max-width: 400px;
  min-width: 200px;

  i {
    flex: auto 0 0;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    color: #aaa;
  }
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  border: 0px;
  height: 33px;
  line-height: 33px;
  padding: 0px 0px 0px 10px;
  width: 100%;
  border-radius: 0px 4px 4px 0px;
`;

function HeaderSearchBar() {
  return (
    <StyledHeaderSearchBar>
      <i className="fal fa-search"></i>
      <SearchInput placeholder="Search" />
    </StyledHeaderSearchBar>
  );
}

export default HeaderSearchBar;
