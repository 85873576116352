import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Drawer } from "./";
import { signOut } from "../firebase";
import { HeaderSearchBar } from "./";

const StyledHeader = styled.header(
  props => `
    display: flex;
    z-index: 2;
    position: fixed;
    width: 100%;
    background-color: #FFF;
    box-sizing: border-box;
    height: 75px;
    padding: 20px;
    box-shadow: ${props.stuck ? "-0px 0px 10px rgba(0,0,0,0.25)" : "none"};
    transition: box-shadow 0.2s ease-in-out;
  `
);

const Logo = styled(Link)`
  font-family: "Playfair Display";
  font-size: 300;
  height: 35px;
  font-size: 1.25em;
  float: left;
  line-height: 30px;
  text-decoration: none;
  color: #000;
  border-radius: 4px;
  margin-left: 8px;
`;

const Burger = styled.a`
  cursor: pointer;
  height: 35px;
  min-width: 35px;
  color: #000;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Divider = styled.div`
  height: 35px;
  border-left: solid 1px #ddd;
  margin: 0px 15px;
`;

const HeaderCentre = styled.div`
  display: flex;
  flex-shrink: 1;
  flex: 1;
  margin: 0px 20px 0px 38px;
  justify-content: space-around;

  @media (max-width: 650px) {
    display: none;
  }
`;

const Menu = styled.nav`
  display: flex;
  margin-left: auto;
`;

const MenuItemIcon = styled(Link)`
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  margin: 0px 5px;
  color: #000;
  text-decoration: none;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Obfuscator = styled.a`
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  pointer-events: none;
  opacity: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &:hover {
    cursor: pointer;
  }
`;

function Header({ isAuthenticated }) {
  let [drawerOpen, setDrawerOpen] = useState(false);
  let [isStuck, setIsStuck] = useState(false);

  function onToggleDrawer(e) {
    setDrawerOpen(!drawerOpen);
  }

  function handleScroll(e) {
    let { scrollTop } = e.srcElement.scrollingElement;

    if (!isStuck && scrollTop > 0) {
      setIsStuck(true);
    } else if (isStuck && scrollTop === 0) {
      setIsStuck(false);
    }
  }

  // Add/remove scroll listener
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  // Toggle body scroll when drawer is open/closed
  useEffect(() => {
    drawerOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [drawerOpen]);

  return (
    <StyledHeader stuck={isStuck}>
      <Burger onClick={onToggleDrawer}>
        <i className="fal fa-bars" />
      </Burger>
      <Divider />
      <Logo to="/">Artemus</Logo>
      {isAuthenticated && (
        <HeaderCentre>
          <HeaderSearchBar />
        </HeaderCentre>
      )}
      <Menu>
        {isAuthenticated ? (
          <>
            <MenuItemIcon to="/preferences">
              <i className="fal fa-cog" />
            </MenuItemIcon>
            <MenuItemIcon to="/about">
              <i className="fal fa-question-circle" />
            </MenuItemIcon>
            <Divider />
            <MenuItemIcon to="/login" onClick={signOut}>
              <i className="fal fa-sign-out" />
            </MenuItemIcon>
          </>
        ) : (
          <MenuItemIcon to="/login">
            <i className="fal fa-sign-in" />
          </MenuItemIcon>
        )}
      </Menu>
      <Drawer
        actions={{
          onToggleDrawer: onToggleDrawer
        }}
        isAuthenticated={isAuthenticated}
        drawerOpen={drawerOpen}
      />
      <Obfuscator
        className={drawerOpen ? "active" : null}
        onClick={onToggleDrawer}
      />
    </StyledHeader>
  );
}

export default Header;
