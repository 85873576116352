import { default as mapTest } from "./test";
import { default as mapEducation } from "./education";
import { default as mapHomelessness } from "./homelessness";

export const test = mapTest;
export const education = mapEducation;
export const homelessness = mapHomelessness;

export default {
  test: mapTest,
  education: mapEducation,
  homelessness: mapHomelessness
};
