import React from "react";
import styled from "styled-components";
import { Toast } from "./components";
import { fadeIn } from "./keyframes";

const Main = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const Form = styled.form`
  box-sizing: border-box;
  width: 300px;
  padding: 20px;
  height: auto;
  margin: auto;
  border-radius: 4px;
  // border: solid 1px #C0C0C0;
  animation: ${fadeIn} 0.75s;
`;

const Title = styled.h1`
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 300;
  height: 40px;
  margin: 0px 0px 25px 0px;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: solid 1px #ccc;
  margin-bottom: 5px;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const SignIn = styled.button`
  box-sizing: content-box;
  border: none;
  font-size: 1em;
  cursor: pointer;
  display: block;
  width: 100px;
  padding: 10px 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  background-color: #112a3a;
  text-align: center;
  margin: 20px auto 0px auto;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;

  &:hover {
    opacity: 0.85;
  }
`;

const SignInIcon = styled.i`
  color: #fff;
  margin-left: 10px;
`;

const Remember = styled.div`
  margin-top: 5px;
`;

const RememberLabel = styled.label`
  font-weight: 300;
  font-size: 0.8em;
  margin-left: 5px;
`;

const Login = ({ actions, formValues, toastMessage, signInButtonIcon }) => (
  <Main>
    <Form>
      <Title>Artemus</Title>
      <Input
        placeholder="Email Address"
        onChange={actions.onEmailChange}
        value={formValues.email}
      />
      <Input
        type="password"
        placeholder="Password"
        onChange={actions.onPasswordChange}
        value={formValues.password}
      />
      <Remember>
        <input
          type="checkbox"
          id="rememberme"
          onChange={actions.onRememberMe}
        />
        <RememberLabel htmlFor="rememberme">Remember me?</RememberLabel>
      </Remember>
      <SignIn type="submit" onClick={actions.onSignIn}>
        Sign In
        <SignInIcon className={signInButtonIcon} />
      </SignIn>
    </Form>
    {toastMessage && (
      <Toast
        actions={{ dismiss: actions.onToastDismiss }}
        message={toastMessage.message}
        type={toastMessage.type}
      />
    )}
  </Main>
);

export default Login;
