import * as Views from "../views";

const routes = {
  login: {
    name: "Login",
    exact: true,
    component: Views.Login,
    path: "/login",
    private: false
  },
  home: {
    name: "Landing",
    exact: true,
    component: Views.Landing,
    path: "/",
    icon: "fal fa-home",
    appearsInDrawer: true,
    private: true
  },
  explore: {
    name: "Explore",
    exact: true,
    component: Views.Explore,
    path: "/explore",
    icon: "fal fa-chart-network",
    appearsInDrawer: true,
    private: false
  },
  preferences: {
    name: "Preferences",
    exact: true,
    component: Views.Preferences,
    path: "/preferences",
    icon: "fal fa-cog",
    appearsInDrawer: true,
    private: true
  },
  admin: {
    name: "Admin",
    exact: true,
    component: Views.Admin,
    path: "/admin",
    icon: "fal fa-lock-alt",
    appearsInDrawer: true,
    private: true
  },
  about: {
    name: "About",
    exact: true,
    component: Views.About,
    path: "/about",
    icon: "fal fa-question-circle",
    appearsInDrawer: true,
    private: true
  },
  error: {
    name: "Error",
    component: Views.Error,
    private: false
  }
};

export default routes;
