import React from "react";
import { PageColumn } from "../ui";

const Landing = props => (
  <PageColumn>
    <h1>Home</h1>
    <p>Some home stuff</p>
  </PageColumn>
);

export default Landing;
