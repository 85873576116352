import React from "react";
import styled, { css } from "styled-components";

const StyledToggle = styled.button`
  font-size: 0.8em;
  line-height: 24px;
  display: block;
  float: left;
  border-radius: 4px;
  border: solid 1px #dddddd;
  width: 74px;
  height: 26px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  position: relative;
  padding: 0px;
  cursor: pointer;
`;

const Options = styled.span`
  display: block;
  overflow: auto;

  span {
    display: block;
    text-align: center;
    padding: 0px 6px;
    width: 24px;
    float: left;
  }
`;

const Slider = styled.span(
  ({ checked }) => css`
    display: block;
    border: 0;
    z-index: 1;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 20px;
    width: 34px;
    border-radius: 2px;
    background-color: #bbbbbb;
    transform: translateX(${checked ? "34px" : "0px"});
    transition: transform 0.2s ease-in-out;
  `
);

const Value = styled.span`
  display: block;
  float: left;
  line-height: 26px;
  margin-left: 12px;
`;

const Toggle = props => {
  function updateCheckedState() {
    props.onChange(!props.value ? 1 : 0);
  }

  return (
    <>
      <StyledToggle onClick={updateCheckedState}>
        <Slider checked={!!props.value} />
        <Options>
          {props.options.map((el, i) => (
            <span key={`${el}${i}`}>{el}</span>
          ))}
        </Options>
      </StyledToggle>
      <Value>{props.valueText}</Value>
    </>
  );
};

export default Toggle;
