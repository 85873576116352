import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components";
import { Header } from "./ui";
import store from "./store";
import { routes } from "./config";
import * as firebase from "./firebase";

class App extends React.Component {
  constructor(props) {
    super(props);

    let initialState = {
      isAuthenticated: false,
      isConnecting: false,
      user: null
    };

    this.state = { ...initialState };

    firebase.authChangeListener.bind(this)();
  }

  componentDidMount() {
    this.setState({ isConnecting: true });
  }

  render() {
    let mutualProps = {
      isAuthenticated: this.state.isAuthenticated,
      isConnecting: this.state.isConnecting,
      user: this.state.user
    };

    return (
      <Provider store={store}>
        <Router>
          <Header {...mutualProps} />
          <Switch>
            {Object.keys(routes).map(route =>
              routes[route].private ? (
                <PrivateRoute
                  key={`route${routes[route].name}`}
                  exact={routes[route].exact}
                  path={routes[route].path}
                  component={routes[route].component}
                  {...mutualProps}
                />
              ) : (
                <PublicRoute
                  key={`route${routes[route].name}`}
                  exact={routes[route].exact}
                  path={routes[route].path}
                  component={routes[route].component}
                  {...mutualProps}
                />
              )
            )}
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
