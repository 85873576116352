import React from "react";
import styled from "styled-components";

const Main = styled.main`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 75px 20px 20px 20px;
  overflow: auto;

  h1 {
    font-family: "Playfair Display";
    font-weight: 300;
  }

  p {
    font-weight: 300;
    text-align: justify;
  }
`;

const PageFull = ({ children }) => (
  <>
    <Main>{children}</Main>
  </>
);

export default PageFull;
