import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { signIn } from "../../firebase";
import { dismissToast } from "../components/Toast";
import Login from "../Login";
import { Spinner } from "../";

function LoginContainer(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [persistence, setPersistence] = useState("SESSION");
  const [toast, setToast] = useState("");
  const [attemptingSignIn, setAttemptingSignIn] = useState(false);

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onPasswordChange(e) {
    setPassword(e.target.value);
  }

  function onRememberMe(e) {
    e.persist();
    e.target.checked ? setPersistence("LOCAL") : setPersistence("SESSION");
  }

  function clearPassword() {
    setPassword("");
  }

  function onSignIn(e) {
    e.preventDefault();
    setAttemptingSignIn(true);
    signIn(email, password, persistence).catch(e => {
      clearPassword();
      setToast({ message: e.message, type: "error" });
      setAttemptingSignIn(false);
    });
  }

  let { isAuthenticated, isConnecting } = props;
  let signInButtonIcon = attemptingSignIn
    ? "far fa-circle-notch fa-spin"
    : "far fa-sign-in";
  let referrer =
    props.location.state !== undefined ? props.location.state.referrer : "/";

  return isConnecting ? (
    <Spinner />
  ) : isAuthenticated ? (
    <Redirect to={referrer} />
  ) : (
    <Login
      actions={{
        onEmailChange: onEmailChange,
        onPasswordChange: onPasswordChange,
        onSignIn: onSignIn,
        onRememberMe: onRememberMe,
        onToastDismiss: e => dismissToast(e, setToast)
      }}
      toastMessage={toast}
      signInButtonIcon={signInButtonIcon}
      formValues={{
        email,
        password,
        persistence,
        attemptingSignIn
      }}
    />
  );
}

export default LoginContainer;
