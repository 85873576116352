import * as Firebase from 'firebase/app';
import firebaseConfig from './config';
import "firebase/auth";
import "firebase/firestore";
export * from './functions';

Firebase.initializeApp(firebaseConfig);
var db = Firebase.firestore();

export const firebaseAuth = Firebase.auth;
export const firebase = Firebase;
export default db;