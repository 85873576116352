import React from "react";
import { PageColumn } from "../ui";
import { Link } from "react-router-dom";

const Error = props => (
  <PageColumn>
    <h1>404</h1>
    <p>Page not found</p>
    <p>
      Return <Link to="/">home</Link>
    </p>
  </PageColumn>
);

export default Error;
