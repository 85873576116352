import React from "react";
import styled from "styled-components";

const Main = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 75px 30px 0px 30px;
  overflow: auto;

  h1 {
    font-family: "Playfair Display";
    font-weight: 300;
  }

  p {
    font-weight: 300;
    text-align: justify;
  }
`;

const PageColumn = ({ children }) => (
  <>
    <Main>{children}</Main>
  </>
);

export default PageColumn;
