import React from "react";
import styled, { css } from "styled-components";
import { PageFull, Toggle } from "../ui";
import { Toast } from "./components";

const UserDetails = styled.table`
  border-collapse: separate;
  border-spacing: 0px 16px;

  tr {
    height: 26px;
  }

  td {
    font-weight: 300;
    padding: 0px;
    padding-right: 25px;

    &:first-child {
      font-weight: 400;
    }

    &:last-child {
      padding-right: 0px;
    }
  }
`;

const UserStatistics = styled.div`
  font-style: italic;
  font-weight: 300;
  margin-top: 25px;
  clear: both;
`;

const EmailVerified = styled.span(
  ({ verified }) => css`
    margin-left: 6px;

    &:before {
      padding: 4px 8px;
      color: white;
      background-color: ${verified ? "#4caf50" : "#f44336"};
      border-radius: 4px;
      font-size: 0.8em;
      font-weight: bold;
      content: "${verified ? "Verified" : "Not Verified"}";
    }
  `
);

const Input = styled.input`
  border: 1px solid #dddddd;
  padding: 4px 4px;
  font-size: 1em;
  box-sizing: border-box;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  font-weight: 300;
  width: 100%;

  &::placeholder {
    color: #aaaaaa;
  }
`;

// const ActionButton = styled.button`
//   padding: 4px 8px;
//   border: 0px;
//   border-radius: 4px;
//   font-size: 1em;
//   cursor: pointer;

//   i {
//     margin-right: 6px;

//     &:only-child {
//       margin-right: 0px;
//     }
//   }

//   &:hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
// `;

const ActionButton = styled.button`
  box-sizing: content-box;
  overflow: auto;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  ${"" /* background-color: #31c100; */}
  background-color: #0081ff;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  float: left;
  margin-right: 15px;

  &:hover {
    opacity: 0.85;
  }

  &[disabled] {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  overflow: auto;
`;

const ButtonIcon = styled.i`
  color: #fff;
  margin-right: 12px;
`;

const Preferences = props => (
  <PageFull>
    <h1>Preferences</h1>
    <UserDetails>
      <tbody>
        <tr>
          <td>Email:</td>
          <td>
            <span>{props.user.email}</span>
            <EmailVerified verified={props.user.emailVerified} />
          </td>
          <td>
            {props.user.emailVerified || (
              <ActionButton onClick={props.actions.onVerifyEmail}>
                <ButtonIcon className="fal fa-envelope" />
                <span>Verify Email</span>
              </ActionButton>
            )}
          </td>
        </tr>
        <tr>
          <td>Display Name:</td>
          <td>
            <Input
              placeholder={props.preferences.displayName || "Anonymous"}
              value={props.formValues.displayName}
              onChange={props.actions.onDisplayNameChange}
            />
          </td>
        </tr>
        <tr>
          <td>Preferred Language:</td>
          <td>
            <Toggle
              value={props.formValues.language}
              valueText={props.formValues.language ? "Cymraeg" : "English"}
              options={["CY", "EN"]}
              onChange={props.actions.onLanguageChange}
            />
          </td>
        </tr>
        <tr>
          <td>Theme:</td>
        </tr>
      </tbody>
    </UserDetails>
    <ButtonGroup>
      <ActionButton
        disabled={props.saveDisabled}
        onClick={props.saveDisabled ? null : props.actions.onSavePreferences}
      >
        <ButtonIcon className="fal fa-save" />
        <span>Save</span>
      </ActionButton>
    </ButtonGroup>
    <UserStatistics>
      Last signed in on {props.user.lastSignInTime}
    </UserStatistics>
    {props.toastMessage && (
      <Toast
        actions={{ dismiss: props.actions.onToastDismiss }}
        message={props.toastMessage.message}
        type={props.toastMessage.type}
      />
    )}
  </PageFull>
);

export default Preferences;
