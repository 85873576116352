import React from "react";
import { PageFull } from "../ui";

const Admin = props => (
  <PageFull>
    <h1>Admin</h1>
    <p>Admin panel</p>
  </PageFull>
);

export default Admin;
