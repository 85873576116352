import React from "react";
import styled from "styled-components";
import CytoscapeComponent from "react-cytoscapejs";
import CyControl, { CyControlGroup } from "./controls/CytoscapeControls";

/*
  TODO: 
  - Support adding collections
    - Current collection is focused (incl. edges) and centred in viewport
  - Add tree of routes (does not need to be headless, separate view?)
    - ...
*/

const StyledMapRenderer = styled.div`
  overflow: hidden;
  height: 100%;
`;

class MapRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.initialCyState = {
      boxSelection: false,
      selectionType: "single",
      autolock: true,
      minZoom: 1,
      zoom: 2,
      maxZoom: 3
    };

    this.initialState = {
      cyState: { ...this.initialCyState },
      selection: null,
      collection: null
    };

    this.state = { ...this.initialState };

    this.cy = null;

    this.cyRecentre = this.cyRecentre.bind(this);
    this.cyFit = this.cyFit.bind(this);
    this.cyToggleAutolock = this.cyToggleAutolock.bind(this);
    this.cyZoomIn = this.cyZoomIn.bind(this);
    this.cyZoomOut = this.cyZoomOut.bind(this);
  }

  componentDidMount() {
    this.cy.ready(e => {
      // Access the current cytoscape object from the console
      window.cy = this.cy;

      this.cy.on("select", "node", e => {
        // Deselect previous node when new selection is made
        this.cy
          .nodes()
          .not(e.target)
          .unselect();

        // Add the current node selection to state
        this.setState({ selection: e.target.id() });

        this.cyRecentre(e);
      });

      // Remove the current node selection from state
      this.cy.on("unselect", "node", e => this.setState({ selection: null }));

      this.cy
        .collection()
        .union("#n1")
        .addClass("collection")
        .select();
    });
  }

  cyRecentre(e) {
    e.preventDefault();

    this.cy.animate({
      fit: {
        eles: this.cy.nodes(":selected"),
        padding: 25
      },
      easing: "ease-in-out",
      duration: 500
    });
  }

  cyFit(e) {
    e.preventDefault();

    this.cy.animate({
      fit: {
        eles: this.cy.nodes(".collection"),
        padding: 25
      },
      easing: "ease-in-out",
      duration: 500
    });
  }

  cyToggleAutolock(e) {
    e.preventDefault();

    this.setState(prevState => ({
      cyState: {
        ...prevState.cyState,
        autolock: !prevState.cyState.autolock
      }
    }));
  }

  cyZoomIn(e) {
    e.preventDefault();
    this.cyChangeZoomLevel(0.5);
  }

  cyZoomOut(e) {
    e.preventDefault();
    this.cyChangeZoomLevel(-0.5);
  }

  cyChangeZoomLevel(inc) {
    this.cy.zoom({
      level: this.cy.zoom() + inc,
      position: this.cy.$id(this.state.selection)
        ? this.cy.nodes(this.cy.$id(this.state.selection)).position()
        : null,
      renderedPosition: this.state.selection ? null : this.cyGetViewportCentre()
    });
  }

  cyGetViewportCentre() {
    return {
      x: this.cy.width() / 2,
      y: this.cy.height() / 2
    };
  }

  cyNodeBackgroundMapper(ele) {
    let bgCol;

    switch (ele.data("type")) {
      case 0:
        bgCol = "#7BCE7A";
        break;
      case 1:
        bgCol = "#8CCA28";
        break;
      case 2:
        bgCol = "#7FABCC";
        break;
      case 3:
        bgCol = "#D3C346";
        break;
      case 4:
        bgCol = "#ACDEB9";
        break;
      case 5:
        bgCol = "#E8B7AE";
        break;
      case 6:
        bgCol = "#F28C79";
        break;
      default:
        bgCol = "#333333";
        break;
    }

    return bgCol;
  }

  render() {
    return (
      <StyledMapRenderer>
        <CytoscapeComponent
          elements={CytoscapeComponent.normalizeElements(this.props.data)}
          style={{
            width: "100%",
            height: "100%"
          }}
          stylesheet={[
            {
              selector: "core",
              style: {
                activeBgSize: 15
              }
            },
            {
              selector: "edge",
              style: {
                lineColor: "#000000",
                targetArrowColor: "#000000",
                opacity: 0.15,
                curveStyle: "bezier",
                lineStyle: "solid",
                width: 1,
                loopDirection: "0deg",
                loopSweep: "30deg",
                sourceEndpoint: "outside-to-node",
                targetEndpoint: "outside-to-node",
                targetArrowShape: "triangle-backcurve"
              }
            },
            {
              selector: "edge:active",
              style: {
                overlayOpacity: 0,
                overlayPadding: 0
              }
            },
            {
              selector: "node",
              style: {
                label: "data(label)",
                opacity: 0.5,
                textWrap: "wrap",
                textMaxWidth: 40,
                width: 50,
                height: 50,
                color: "black",
                textValign: "center",
                textHalign: "center",
                fontSize: "0.4em",
                fontFamily: "Roboto",
                fontWeight: 400,
                borderWidth: 2,
                borderColor: this.cyNodeBackgroundMapper,
                padding: 5,
                backgroundColor: this.cyNodeBackgroundMapper
              }
            },
            {
              selector: "node:active",
              style: {
                borderColor: "#505050",
                opacity: 1,
                overlayOpacity: 0,
                overlayPadding: 0
              }
            },
            {
              selector: "node:selected",
              style: {
                borderColor: "red",
                opacity: 1
              }
            },
            {
              selector: ".collection",
              style: {
                opacity: 1
              }
            }
          ]}
          {...this.state.cyState}
          cy={cy => {
            this.cy = cy;
          }}
        />
        <CyControlGroup position="top">
          <CyControl
            onclick={this.cyZoomIn}
            icon="fal fa-plus"
            dataTooltip="Zoom In"
            dataTooltipPosition="right"
          />
          <CyControl
            onclick={this.cyZoomOut}
            icon="fal fa-minus"
            dataTooltip="Zoom Out"
            dataTooltipPosition="right"
          />
          <CyControl
            onclick={this.cyFit}
            icon="fal fa-compress-alt"
            dataTooltip="Zoom to Fit"
            dataTooltipPosition="right"
          />
          <CyControl
            onclick={this.cyRecentre}
            condition={this.state.selection === null}
            icon="fal fa-crosshairs"
            dataTooltip="Zoom to Focus"
            dataTooltipPosition="right"
          />
        </CyControlGroup>
        <CyControlGroup position="bottom">
          <CyControl
            onclick={this.cyToggleAutolock}
            condition={this.state.cyState.autolock}
            icon="fal fa-lock-open"
            dataTooltip="Lock Graph"
            dataTooltipPosition="right"
          />
          <CyControl
            onclick={this.cyToggleAutolock}
            condition={!this.state.cyState.autolock}
            icon="fal fa-lock"
            dataTooltip="Unlock Graph"
            dataTooltipPosition="right"
          />
        </CyControlGroup>
      </StyledMapRenderer>
    );
  }
}

export default MapRenderer;
