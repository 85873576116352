import { CHANGE_APP_NAME } from '../actions/action-types';

const initialState = {
  appName: "Artemus"
};

export function PreferencesReducer(state = initialState, action) {
  switch(action.type) {
    case CHANGE_APP_NAME: {
      return {...state, appName: action.payload.appName}
    }
    default: {}
  }
  return state;
}
