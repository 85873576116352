import React from "react";
import styled from "styled-components";
import MapRenderer from "../components/MapRenderer";
import { PageFull } from "../ui";
import Maps from "../maps";

const MapPane = styled.div`
  position: relative;
  box-sizing: border-box;
  float: left;
  height: 100%;
  width: calc(50% - 15px);
  border: solid 1px #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
`;

const JourneyPane = styled.div`
  overflow-y: scroll;
  box-sizing: border-box;
  float: left;
  height: 100%;
  width: calc(50% + 15px);
  padding-left: 30px;

  h1 {
    margin-top: 0px;
  }

  h2 {
    clear: both;
  }

  button {
    margin-bottom: 25px;
  }
`;

const Disclaimer = styled.div`
  background-color: #d9eaff;
  color: #3c4983;
  padding: 15px;
  font-weight: 300;
  font-size: 0.9em;
  margin-bottom: 15px;
  overflow: auto;
  box-sizing: border-box;
`;

// const DisclaimerIcon = styled.i`
//   float: left;
//   color: #3c4983;
//   margin-right: 10px;
//   font-size: 0.9em;
//   height: 12px;
// `;

const StartButton = styled.button`
  box-sizing: content-box;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  background-color: #31c100;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  float: left;
  margin-right: 15px;

  &:hover {
    opacity: 0.85;
  }
`;

const TakeOptionButton = styled.button`
  box-sizing: content-box;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  background-color: #31c100;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  float: left;
  margin-right: 15px;

  &:hover {
    opacity: 0.85;
  }
`;

const ButtonIcon = styled.i`
  color: #fff;
  margin-right: 12px;
`;

const LearnMoreButton = styled.button`
  box-sizing: content-box;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  background-color: #0081ff;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  float: left;
  margin-right: 15px;

  &:hover {
    opacity: 0.85;
  }
`;

const ExploreOptionsButton = styled.button`
  box-sizing: content-box;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  background-color: #da5581;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;

  &:hover {
    opacity: 0.85;
  }
`;

const StepOpen = styled.i`
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 15px;
`;

const StepClosed = styled.i`
  font-size: 0.8em;
  color: rgba(0, 0, 0, 1);
  margin-right: 15px;
`;

const StepOne = props => (
  <>
    <Disclaimer>
      {/* <DisclaimerIcon className="fal fa-info-circle" /> */}
      <strong>Disclaimer:</strong> Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Sed vel mattis enim. Curabitur bibendum, eros eget
      sodales viverra, justo nibh aliquam nisi, quis ornare massa lorem a enim.
    </Disclaimer>
    <StartButton onClick={props.actions.start}>
      <ButtonIcon className="fal fa-play" />
      Start
    </StartButton>
  </>
);

const StepTwo = props => (
  <>
    <h2>
      <StepOpen className="fal fa-chevron-circle-down" />
      Local Authority
    </h2>
    <p>
      The local authority considers a person's application and notifies the
      applicant of their decision
    </p>
    <LearnMoreButton onClick={props.actions.learnMore}>
      <ButtonIcon className="fal fa-book" />
      Learn More
    </LearnMoreButton>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Decision Point
    </h2>
    <p>There are options available.</p>
    <ExploreOptionsButton onClick={props.actions.exploreOptions}>
      <ButtonIcon className="fal fa-shoe-prints" />
      Explore Options
    </ExploreOptionsButton>
  </>
);

const OptionsContainer = styled.div`
  overflow: auto;
  h3 {
    font-weight: 300;
  }
`;

const StepThree = props => (
  <>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Local Authority
    </h2>
    <h2>
      <StepOpen className="fal fa-chevron-circle-down" />
      Decision Point
    </h2>
    <p>There are options available.</p>
    <OptionsContainer>
      <h3>
        Option 1: <strong>Not eligible for help</strong>
      </h3>
      <p>
        The local authority has decided that a person is not eligible for help.
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 2: <strong>Person is not homeless</strong>
      </h3>
      <p>The local authority has decided that a person is not homeless.</p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 3: <strong>Referral to another local authority</strong>
      </h3>
      <p>
        A person has applied for accommodation and the local authority decides
        to refer them to a different local authority.
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 4:{" "}
        <strong>Interim Accommodation for people in priority need</strong>
      </h3>
      <p>
        Where an authority thinks a person applying for help may be homeless,
        eligible for help, and has a priority need for accommodation, it must
        secure that suitable accommodation is available to the person whilst it
        investigates and decides their application.  
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 5: <strong>Interim accommodation duty has ended</strong>
      </h3>
      <p>
        The duty to accommodate a person whilst the local authority is
        investigating their application ends in certain circumstances including
        where the local authority investigates and decides that it does not owe
        a duty to accommodate the person.  
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 6: <strong>Initial duty to secure accomodation has ended</strong>
      </h3>
      <p>
        Local authority decides that its duty to secure accommodation for the
        applicant has ended.    
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 7: <strong>Person is not in priority need</strong>
      </h3>
      <p>
        The duty to provide a person with interim accommodation while a local
        authority is investigating their application, and the final or main duty
        to secure accommodation after 56 days, only apply to people in priority
        need.     
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 8: <strong>Person is intentionally homeless</strong>
      </h3>
      <p>
        When deciding whether to provide a person with interim accommodation
        whilst investigating their application, or when deciding whether to
        secure accommodation for a person under the final or main duty, a local
        authority may have regard to whether the person is intentionally
        homeless.
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 9: <strong>Accommodation is suitable</strong>
      </h3>
      <p>
        A local authority that has a duty to secure accommodation to a person
        must provide accommodation that is suitable, for example as concerns
        housing conditions and overcrowding. A person may feel that
        accommodation provided is not suitable, for example because it is
        overcrowded.      
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 10: <strong>Refusal to conduct a homelessness assessment</strong>
      </h3>
      <p>
        A local authority refuses to investigate a person's application for help
        to secure accommodation.  
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 11: <strong>Protection of homeless person's property</strong>
      </h3>
      <p>
        Where a local authority has a duty to secure accommodation for a
        homeless person in priority need it also has a duty to take reasonable
        steps to protect that person’s property if it has reason to believe that
        there is a danger of loss of, or damage to the property because the
        homeless person is unable to look after it.  
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 12: <strong>Other</strong>
      </h3>
      <p>
        Where none of the other above specific legal issues apply, a person may
        still feel that they have been treated unfairly by the local authority,
        or received a poor service, for example where staff have been rude or
        unco-operative, where telephone calls or emails are not answered within
        a reasonable time.    
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
  </>
);

const StepFour = props => (
  <>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Local Authority
    </h2>
    <h2>
      <StepOpen className="fal fa-chevron-circle-down" />
      Local Authority reviews its own decision
    </h2>
    <p>The local authority reviews its own decision.</p>
    <LearnMoreButton onClick={props.actions.learnMore}>
      <ButtonIcon className="fal fa-book" />
      Learn More
    </LearnMoreButton>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Decision Point
    </h2>
    <p>There are options available.</p>
    <ExploreOptionsButton onClick={props.actions.exploreOptions}>
      <ButtonIcon className="fal fa-shoe-prints" />
      Explore Options
    </ExploreOptionsButton>
  </>
);

const StepFive = props => (
  <>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Local Authority
    </h2>
    <h2>
      <StepClosed className="fal fa-chevron-circle-right" />
      Local Authority reviews its own decision
    </h2>
    <h2>
      <StepOpen className="fal fa-chevron-circle-down" />
      Decision Point
    </h2>
    <p>There are options available.</p>
    <OptionsContainer>
      <h3>
        Option 1: <strong>Local authority confrms original decision</strong>
      </h3>
      <p>The local authority confirms its original decision.</p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 2: <strong>Local authority changes its decision</strong>
      </h3>
      <p>
        Local authority changes its original decision either wholly or partially
        in favour of the applicant 
      </p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
    <OptionsContainer>
      <h3>
        Option 3: <strong>Local authority refuses to carry out a review</strong>
      </h3>
      <p>Local authority refuses to carry out a review</p>
      <LearnMoreButton onClick={props.actions.learnMore}>
        <ButtonIcon className="fal fa-book" />
        Learn More
      </LearnMoreButton>
      <TakeOptionButton onClick={props.actions.takeOption}>
        <ButtonIcon className="fal fa-shoe-prints" />
        Take Option
      </TakeOptionButton>
    </OptionsContainer>
  </>
);

class Explore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: Maps.homelessness,
      step: 1
    };

    this.start = this.start.bind(this);
    this.exploreOptions = this.exploreOptions.bind(this);
    this.takeOption = this.takeOption.bind(this);
    this.exploreOptions2 = this.exploreOptions2.bind(this);
  }

  start() {
    console.log("Start");
    this.setState({ step: 2 });
  }

  exploreOptions() {
    console.log("Explore Options");
    this.setState({ step: 3 });
    window.cy.edges('[source="n1"][target="n1"]').addClass("collection");
  }

  takeOption() {
    console.log("Take Option");
    this.setState({ step: 4 });

    window.cy.nodes("#n6, #n8").addClass("collection");
    window.cy.edges('[source="n1"][target="n6"]').addClass("collection");
    window.cy.edges('[source="n1"][target="n8"]').addClass("collection");

    window.cy.animate({
      fit: {
        eles: window.cy.nodes(".collection"),
        padding: 25
      },
      easing: "ease-in-out",
      duration: 500
    });
  }

  exploreOptions2() {
    console.log("Explore Options 2");
    this.setState({ step: 5 });
  }

  render() {
    return (
      <PageFull>
        <MapPane>
          <MapRenderer data={this.state.data} />
        </MapPane>
        <JourneyPane>
          <h1>
            Issue: <strong>Homelessness</strong>
          </h1>
          <p>
            A person has applied to the local authority for help with a
            homelessness situation.
          </p>
          {this.state.step === 1 ? (
            <StepOne actions={{ start: this.start }} />
          ) : null}
          {this.state.step === 2 ? (
            <StepTwo
              actions={{ learnMore: null, exploreOptions: this.exploreOptions }}
            />
          ) : null}
          {this.state.step === 3 ? (
            <StepThree
              actions={{ learnMore: null, takeOption: this.takeOption }}
            />
          ) : null}
          {this.state.step === 4 ? (
            <StepFour
              actions={{
                learnMore: null,
                exploreOptions: this.exploreOptions2
              }}
            />
          ) : null}
          {this.state.step === 5 ? (
            <StepFive
              actions={{
                learnMore: null,
                exploreOptions: null
              }}
            />
          ) : null}
        </JourneyPane>
      </PageFull>
    );
  }
}

export default Explore;
