import React from "react";
import styled, { css } from "styled-components";
import { fadeIn } from "../keyframes";

function toastType(type) {
  switch (type) {
    case "error":
      return {
        colour: "#e25454",
        icon: "fas fa-exclamation-triangle"
      };
    case "success":
      return {
        colour: "#4caf50",
        icon: "fas fa-check"
      };
    default:
      return {
        colour: "transparent",
        icon: ""
      };
  }
}

const StyledToast = styled.div(
  props => css`
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    box-sizing: border-box;
    max-width: 400px;
    padding: 10px 15px;
    margin: 0 auto;
    height: 40px;
    line-height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 0.75em;
    border-radius: 4px;
    background-color: ${toastType(props.type).colour};
    animation: ${fadeIn} 0.75s;
  `
);

const ToastIcon = styled.i`
  margin-right: 10px;
`;

const ToastDismissButton = styled.button`
  float: right;
  padding: 0px 6px;
  line-height: 20px;
  border: 0;
  background-color: transparent;
  font-size: 1em;
  cursor: pointer;
  border-radius: 2px;

  i {
    margin-right: 6px;
    color: #ffffff;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &::after {
    content: "Dismiss";
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
  }
`;

const Toast = props => (
  <StyledToast message={props.message} type={props.type}>
    <ToastIcon className={toastType(props.type).icon}></ToastIcon>
    {props.message}
    <ToastDismissButton onClick={props.actions.dismiss}>
      <i className="fas fa-times"></i>
    </ToastDismissButton>
  </StyledToast>
);

export function dismissToast(e, setToast) {
  e.preventDefault();
  setToast("");
}

export default Toast;
