import React from "react";
import styled, { keyframes } from "styled-components";

const Page = styled.main`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
`;

const keyframesSpin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 8px #112a3a;
  border-right: solid 8px transparent;
  margin: auto;
  animation: ${keyframesSpin} 0.5s infinite linear;
`;

const Spinner = () => (
  <Page>
    <StyledSpinner />
  </Page>
);

export default Spinner;
