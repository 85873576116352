import React from "react";
import styled, { css } from "styled-components";

export const StyledCyControl = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  position: relative;
  font-size: 1em;
  user-select: none;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  color: #404040;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &[data-tooltip]::before,
  &[data-tooltip]::after {
    position: absolute;
    opacity: 0;
    color: #eee;
    box-sizing: border-box;
    transition: opacity 0.25s ease-in-out;
    user-select: none;
    pointer-events: none;
  }

  &[data-tooltip]::before {
    content: attr(data-tooltip);
    padding: 5px 10px;
    background-color: #202020;
    line-height: 12px;
    white-space: nowrap;
    font-size: 0.75em;
    border-radius: 3px;
  }

  &[data-tooltip]::after {
    content: "";
    background-color: transparent;
  }

  &[data-tooltip]:hover::before,
  &[data-tooltip]:hover::after {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  &[data-tooltip-position="right"]::before {
    top: calc(50% - 11px);
    left: 47px;
  }

  &[data-tooltip-position="right"]::after {
    top: calc(50% - 6px);
    left: 41px;
    border-right: solid 6px #202020;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
  }

  &[data-tooltip-position="bottom"]::before {
  }

  &[data-tooltip-position="left"]::before {
  }
`;

export const CyControlGroup = styled.div(
  props => css`
    box-sizing: border-box;
    padding: 2px;
    width: 42px;
    border: solid 1px #ddd;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    ${props.position || "top"}: 5px;
    left: 5px;
    display: flex;
    flex-direction: column;
  `
);

const CyControl = props => (
  <>
    {props.condition ? null : (
      <StyledCyControl
        onClick={props.onclick}
        data-tooltip={props.dataTooltip}
        data-tooltip-position={props.dataTooltipPosition}
      >
        <i className={props.icon}></i>
      </StyledCyControl>
    )}
  </>
);

export default CyControl;
