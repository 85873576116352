import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routes } from "../config";
import { signOut } from "../firebase";

const StyledDrawer = styled.div`
  position: fixed;
  overflow: auto;
  z-index: 2;
  top: 0px;
  left: -350px;
  width: 250px;
  height: 100%;
  transition: left 0.2s ease-in-out;
  background-color: #fff;

  &.active {
    left: 0px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    opacity: 0;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:horizontal {
      height: 8px;
    }

    &:vertical {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
  }

  &::-webkit-resizer {
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 75px;
  padding: 20px;
  overflow: auto;
  line-height: 35px;
  font-family: "Playfair Display";
`;

const DrawerClose = styled.a`
  cursor: pointer;
  height: 35px;
  width: 35px;
  color: #000;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DrawerItems = styled.nav`
  box-sizing: border-box;
  padding: 10px 0px;
  clear: both;
`;

const Logo = styled(Link)`
  font-family: "Playfair Display";
  font-size: 300;
  height: 35px;
  font-size: 1.25em;
  line-height: 30px;
  text-decoration: none;
  color: #000;
  border-radius: 4px;
  margin-left: 8px;
`;

const Divider = styled.div`
  height: 35px;
  border-left: solid 1px #ddd;
  margin: 0px 15px;
`;

const DrawerItem = styled(Link)`
  box-sizing: border-box;
  padding: 5px 20px;
  display: block;
  width: 100%;
  line-height: 40px;
  color: #000;
  text-decoration: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DrawerItemIcon = styled.i`
  width: 35px;
  text-align: center;
  color: #000;
`;

const DrawerItemText = styled.span`
  margin-left: 20px;
  color: #000;
  font-weight: 300;
`;

const DrawerItemDivider = styled.div`
  margin: 15px auto 15px auto;
  height: 1px;
  width: 100%;
  background-color: #ddd;
`;

const Drawer = ({ actions, drawerOpen, isAuthenticated }) => (
  <StyledDrawer className={drawerOpen ? "active" : null}>
    <DrawerHeader>
      <DrawerClose onClick={actions.onToggleDrawer}>
        <i className="fal fa-times" />
      </DrawerClose>
      <Divider />
      <Logo to="/" onClick={actions.onToggleDrawer}>
        Artemus
      </Logo>
    </DrawerHeader>
    <DrawerItems>
      {Object.keys(routes).map((el, i) =>
        (routes[el].appearsInDrawer && !routes[el].private) ||
        (routes[el].private && isAuthenticated) ? (
          <DrawerItem
            key={`${routes[el].name}${i}`}
            to={routes[el].path}
            onClick={actions.onToggleDrawer}
          >
            <DrawerItemIcon className={routes[el].icon} />
            <DrawerItemText>{routes[el].name}</DrawerItemText>
          </DrawerItem>
        ) : null
      )}
      {!isAuthenticated ? (
        <DrawerItem to="/login" onClick={actions.onToggleDrawer}>
          <DrawerItemIcon className="fal fa-sign-in" />
          <DrawerItemText>Sign In</DrawerItemText>
        </DrawerItem>
      ) : (
        <>
          <DrawerItemDivider />
          <DrawerItem
            to="/login"
            onClick={() => {
              actions.onToggleDrawer();
              signOut();
            }}
          >
            <DrawerItemIcon className="fal fa-sign-out" />
            <DrawerItemText>Sign Out</DrawerItemText>
          </DrawerItem>
        </>
      )}
    </DrawerItems>
  </StyledDrawer>
);

export default Drawer;
