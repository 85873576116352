import React, { useState, useEffect } from "react";
import { Spinner } from "../";
import { verifyEmail, getPreferences, updatePreferences } from "../../firebase";
import { dismissToast } from "../components/Toast";
import Preferences from "../Preferences";

function PreferencesContainer(props) {
  const [toast, setToast] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [displayName, setDisplayName] = useState("");
  const [language, setLanguage] = useState(0);
  const [preferences, setPreferences] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  // Load preferences from Firebase
  useEffect(() => {
    const fetchPreferences = async () => {
      let req = await getPreferences();
      setPreferences(req);
      setLanguage(req.language);
      setIsDataLoading(false);
    };
    fetchPreferences();
  }, []);

  // Save button enabled or disabled
  useEffect(() => {
    if (preferences) {
      if (displayName !== "" || language !== preferences.language) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    }
  }, [preferences, displayName, language]);

  function onVerifyEmail(e) {
    verifyEmail()
      .then(e => {
        setToast({ message: e.message, type: "success" });
      })
      .catch(e => {
        setToast({ message: e.message, type: "error" });
      });
  }

  function onDisplayNameChange(e) {
    e.persist();
    setDisplayName(e.target.value);
  }

  function onLanguageChange(e) {
    setLanguage(e);
  }

  function onSavePreferences() {
    // Check whether display name was changed
    let newDisplayName =
      displayName === "" ? preferences.displayName : displayName;

    updatePreferences({
      displayName: newDisplayName,
      language
    })
      .then(e => {
        setPreferences({
          displayName: newDisplayName,
          language
        });
        clearFields();
        setToast({ message: e.message, type: "success" });
      })
      .catch(e => {
        setToast({ message: e.message, type: "error" });
      });
  }

  function clearFields() {
    setDisplayName("");
  }

  return isDataLoading ? (
    <Spinner />
  ) : (
    <Preferences
      {...props}
      actions={{
        onToastDismiss: e => dismissToast(e, setToast),
        onVerifyEmail,
        onDisplayNameChange,
        onLanguageChange,
        onSavePreferences
      }}
      preferences={{ ...preferences }}
      saveDisabled={saveDisabled}
      formValues={{ displayName, language }}
      toastMessage={toast}
    />
  );
}

export default PreferencesContainer;
